import Link from 'next/link';
import React from 'react';

type BottomLinkProps = {
  spanCopy: string;
  linkCopy: string;
  linkUrl: string;
  onClickLink?: () => void;
};

const BottomLink = ({
  spanCopy,
  linkCopy,
  linkUrl,
  onClickLink,
}: BottomLinkProps) => {
  return (
    <Link
      className="block w-full px-6 py-2 text-m14 text-grey-45"
      href={linkUrl}
      onClick={onClickLink}
    >
      {linkCopy}{' '}
      <span className="font-semibold text-secondary-blue-400">{spanCopy}</span>
    </Link>
  );
};

export default BottomLink;
