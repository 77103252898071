'use client';

import { PicoClient } from '@bendingspoons/pico-core';
import { DependencyKey } from '@bendingspoons/web-composable-architecture';

import { Pico } from '@/pico/Pico';

export const pico: DependencyKey<PicoClient> = {
  key: Symbol('pico'),
  get liveValue(): PicoClient {
    return Pico.shared;
  },
};
