'use client';

import {
  DependencyKey,
  unimplemented,
} from '@bendingspoons/web-composable-architecture';

export type SessionStorage = Pick<
  Storage,
  'setItem' | 'getItem' | 'removeItem'
>;

// ! Important this dep should use the session storage or similar storage with same session storage req:
// - session storage is not shared between tabs / windows but it is unique in a same tab
// - session storage is not shared between different origins
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API#concepts_and_usage

export const sessionStorage: DependencyKey<SessionStorage> = {
  key: Symbol('sessionStorage'),
  // This is the only way to make it avoid hydration issues.
  // Best way should be having it declared in a dynamic declared component https://github.com/vercel/next.js/discussions/15181
  liveValue:
    typeof window === 'undefined'
      ? unimplemented('sessionStorage')
      : window.sessionStorage,
};
