import React from 'react';
import { useTranslations } from 'use-intl';

import Button from './Button';

type SocialAuthButtonsProps = {
  onGoogleClick: () => void;
  onAppleClick: () => void;
};

const SocialAuthButtons = ({
  onGoogleClick,
  onAppleClick,
}: SocialAuthButtonsProps) => {
  const t = useTranslations('form');

  return (
    <>
      <div className="relative left-0 w-full after:absolute after:top-1/2 after:-z-10 after:block after:h-[1px] after:w-full after:bg-grey-65">
        <span className="bg-surface-fill-primary-enabled px-4 text-r14 text-grey-65">
          {t('or-label')}
        </span>
      </div>
      <div className="flex w-full flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        <Button
          onClick={onGoogleClick}
          buttonStyle="secondary"
          storeType="google"
        >
          {t('google-label')}
        </Button>
        <Button
          onClick={onAppleClick}
          buttonStyle="secondary"
          storeType="apple"
        >
          {t('apple-label')}
        </Button>
      </div>
    </>
  );
};

export default SocialAuthButtons;
