'use client';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import ErrorMessage from '@/components/ui/ErrorMessage';

type InputEmailProps = {
  placeholder: string;
  errorMessage: string | React.ReactNode | null;
  onChange: (value: string) => void;
  value: string;
  avoidFocus?: boolean;
};

export function isValidEmailCheck(value: string) {
  const emailRegex = /^[^@\n]+@[^@\n]+\.[^@\n]+$/;
  return emailRegex.test(value);
}

const InputEmail = forwardRef<HTMLInputElement, InputEmailProps>(
  ({ placeholder, errorMessage, onChange, value, avoidFocus }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const onChangeInputRef = useRef<typeof onChange>(onChange); // trick to avoid recompute detection on onChange function update
    const internalValue = useRef<string>(''); // trick to detect if updates comes from outside

    // Forward the ref while keeping access to it internally
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    // Focus on the input when the component mounts, unless avoidFocus flag is active
    useEffect(() => {
      if (inputRef.current && !avoidFocus) {
        inputRef.current.focus();
      }
    }, [avoidFocus]);

    // detect unsynced external update (needed otherwise onChange won't be retrigger if change is due to external prop update)
    // needed to avoid breaking any validation logic
    useEffect(() => {
      if (internalValue.current !== value) {
        internalValue.current = value;
        onChangeInputRef.current(value);
      }
    }, [value]);

    // Update the email state on input change
    const handleEmailChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove spaces from the email to avoid false negatives
        const emailWithoutSpaces = event.target.value.replace(/\s/g, '');
        internalValue.current = emailWithoutSpaces;
        onChangeInputRef.current(emailWithoutSpaces);
      },
      []
    );

    const showError = errorMessage !== null;

    return (
      <>
        <input
          ref={inputRef}
          onChange={handleEmailChange}
          id="email"
          type="text"
          autoCapitalize="off"
          placeholder={placeholder}
          value={value}
          className={`${
            showError
              ? 'border-secondary-red-400 outline-secondary-red-400!'
              : 'border-grey-80'
          } rounded-s border py-3 pl-3 pr-2 text-r15 text-grey-15 placeholder:text-grey-65`}
        />
        {showError && (
          <ErrorMessage message={errorMessage} textAlignment={'left'} />
        )}
      </>
    );
  }
);

InputEmail.displayName = 'InputEmail';

export default InputEmail;
